<template>
  <b-card class="px-1 h-100 rounded-lg">
    <div
      class="border-bottom-primary pb-50 d-flex flex-wrap justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <h4 class="font-weight-bolder text-uppercase m-0 mr-1">
          FOR CLASSIFICATION
        </h4>
        <img
          width="25"
          height="25"
          src="https://img.icons8.com/external-bluetone-bomsymbols-/91/external-general-general-office-bluetone-bluetone-bomsymbols--8.png"
          alt="external-general-general-office-bluetone-bluetone-bomsymbols--8"
        />
      </div>

      <div class="d-flex w-auto">
        <b-button
          @click="typeView = 'list'"
          :variant="typeView == 'list' ? 'primary' : 'outline-primary'"
          class="mr-50 p-50"
        >
          <feather-icon icon="ListIcon" size="18" />
        </b-button>
        <b-button
          @click="typeView = 'graphic'"
          :variant="typeView == 'graphic' ? 'primary' : 'outline-primary'"
          class="p-50"
        >
          <feather-icon icon="BarChart2Icon" size="18" />
        </b-button>
      </div>
    </div>

    <hr class="m-0 mb-2" style="border: 1px solid #0090e7; width: 100%" />
    <b-skeleton-table
      :count="skeletonCount"
      :width="skeletonWidth"
      :height="skeletonHeight"
      animated
      v-if="isLoading"
    >
    </b-skeleton-table>
    <b-table-simple
      v-show="!isLoading"
      responsive
      :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
      v-if="typeView == 'list'"
    >
      <b-thead>
        <b-tr class="py-1">
          <b-th>STATE</b-th>
          <b-th>SETTLED</b-th>
          <b-th>CONFIRMATION</b-th>
          <b-th>NOT SETTLED</b-th>
          <b-th>TOTAL</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(value, key) in classificationItems" :key="key">
          <b-td>
            <div
              class="d-flex align-items-center w-100 py-2 px-2 custom-td-item text-primary font-weight-bolder"
            >
              {{ value.name }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.settled != 0
                ? openModal(key, `${value.name} - SETTLED`, 1)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.settled != 0 ? 'hoverable-row' : null"
            >
              {{ value.settled }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.confirmation != 0
                ? openModal(key, `${value.name} - CONFIRMATION`, 2)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.confirmation != 0 ? 'hoverable-row' : null"
            >
              {{ value.confirmation }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.not_settled != 0
                ? openModal(key, `${value.name} - NOT SETTLED`, 3)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.not_settled != 0 ? 'hoverable-row' : null"
            >
              {{ value.not_settled }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.total != 0 ? openModal(key, `${value.name} - TOTAL`) : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.total != 0 ? 'hoverable-row' : null"
            >
              {{ value.total }}
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div v-else class="d-block">
      <graphics-components
        :xAxisData="xAxisData"
        :yAxisData="yAxisData"
        @selectItemChart="selectItemChart"
      />
    </div>
  </b-card>
</template>
<script>
import GraphicsComponents from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/Graphics.vue";

export default {
  components: {
    GraphicsComponents,
  },
  props: {
    isLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    year: [Number, String],
    month: [Number, String],
    source: [Number],
    type: [Number],
    classificationItems: {
      required: true,
      type: Array,
      default: () => [],
    },
    viewDefault: {
      type: String,
      default: "list",
      required: false,
    },
  },
  mounted() {
    if (!!this.viewDefault) {
      this.typeView = this.viewDefault;
    }
  },
  data() {
    return {
      skeletonCount: 5,
      skeletonWidth: "100%",
      skeletonHeight: "2rem",
      typeView: "list",
      xAxisData: [],
      yAxisData: {},
    };
  },
  methods: {
    openModal(index, title, classification = null) {
      const params={
        item: index + 1,
        classification: classification,
        title: title,
        classification_ncr_id: null,
      }
      this.$emit("openModal", params);
    },
    generateChart() {
      const seriesA = this.classificationItems.map((item) => item.settled);
      const seriesB = this.classificationItems.map((item) => item.confirmation);
      const seriesC = this.classificationItems.map((item) => item.not_settled);

      // params to graphic
      this.yAxisData = {
        SETTLED: seriesA,
        CONFIRMATION: seriesB,
        "NOT SETTLED": seriesC,
      };

      // const xAxisData = this.classificationItems.map((item) => item.name);
      const xAxisData = ['by soft', 'by specialists','by seller', 'sold','offered', 'settleds'];
      const newXAxisData = xAxisData.map((item, index) => {
        const total = parseInt(seriesA[index]) + parseInt(seriesB[index]) + parseInt(seriesC[index]);
        return {
          name: item,
          total
        }
      });
      this.xAxisData = newXAxisData;
    },
    selectItemChart({ xAxisIndex, yAxisIndex }) {
      const xAxisItem = this.classificationItems[xAxisIndex];
      const yAxisItem = Object.keys(this.yAxisData)[yAxisIndex];

      // Validar los índices recibidos
      if (xAxisItem && yAxisItem) {
        const { name } = xAxisItem;
        const itemName = `${name} - ${yAxisItem}`;
        this.openModal(xAxisIndex, itemName, yAxisIndex + 1);
      } else {
        console.error("Índices de ejes no válidos.");
      }
    },
  },
  watch: {
    typeView(newValue) {
      if (newValue) {
        this.generateChart();
      }
      this.$emit("selectView", newValue);
    },
  },
};
</script>
<style scoped lang="scss" >
@import "../commons/style/shared-styles.scss";
</style>
