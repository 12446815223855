<template>
  <b-modal
    v-model="isActive"
    size="xl"
    hide-footer
    scrollable
    @hidden="closeModal"
    body-class="p-0"
  >
    <template #modal-title>
      <div class="m-0 py-50">
          <div class="d-flex align-items-center">
              <tabler-icon
                  icon="BuildingBankIcon"
                  size="18"
                  class="mr-1"
              ></tabler-icon>
              <div class="text-uppercase text-white font-weight-bolder" style="margin-top: 5px;">
                  {{ title }} - DETAILS FOR SETTLEMENT JOURNEY
              </div>
          </div>
          <div>
            <ShowFilters :filters="filters" />
          </div>
      </div>
    </template>
    <b-container fluid class="py-1 px-2">
        <b-row>
          <b-col cols="12" xl="3">
            <div class="d-block">
              <list-leads
                :key="keyLeads"
                :data="dataLeads"
                @selectedItem="selectedLead"
                :paginate="paginateLeads"
                @selectedPage="selectedPage"
                :tab="'settlements'"
                :inputSearchLeads="inputSearchLeads"
                @search="getLeads"
              />
            </div>
          </b-col>
          <b-col cols="12" xl="9" class="p-1">
            <div class="p-2 rounded-lg h-100" :class="isDarkSkin ? 'custom-bg-gray-dark':'custom-bg-gray'">
              <h4 class="p-1 text-uppercase font-weight-bolder">
                ACCOUNTS LIST OF LEAD
                <span class="text-primary" v-if="!!currentItemLead.client_name">{{currentItemLead.client_name || ''}}</span>
              </h4>
                  <b-table
                    slot="table"
                    ref="listTable"
                    class="blue-scrollbar custom-table"
                    :items="items"
                    :fields="visibleFields"
                    :busy.sync="isBusy"
                    show-empty
                    no-provider-filtering
                    striped
                    sticky-header="50vh"
                  >
                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template #cell(item)="{ index, item }">
                      <div class="d-flex justify-content-center align-items-center custom-main-td-height">
                        <span>{{ index + 1 }}</span>
                      </div>
                      <ul class="list-unstyled mt-50" v-if="item.showDetail">
                        <li v-for="(item, index) in textObject.length" :key="index" class="custom-heigh-detail">
                          <!--is empty-->
                        </li>
                      </ul>
                    </template>
                    <!-- <template #cell(lead_name)="{ value }">
                      <span class="text-primary">{{ value }}</span>
                    </template> -->
                    <template #cell(accounts)="{ item }">
                      <div class="d-flex flex-column align-items-center justify-content-center py-1" style="width: 200px">
                        <span
                          class="d-block align-items-center text-truncate font-size-md font-weight-bolder w-custom"
                          style="cursor: default; padding: 0 10px; width: 200px"
                          v-b-tooltip:hover
                          :title="item.negotiate.creditor_name"
                        >
                          {{ item.negotiate.creditor_name }}
                        </span>

                        <span
                          style="font-size: 13px; cursor: default; width: 150px"
                          class="my-1 d-block text-truncate text-capitalize w-custom"
                          :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                          v-b-tooltip:hover
                          :title="item.negotiate.account_number"
                          >Account #: {{ item.negotiate.account_number }}</span
                        >
                      </div>
                    </template>
                    <template #row-details="{ item }">
                      <b-row class="pt-2 pb-1 px-2 border-top">
                        <b-col cols="4" class="px-2 border-right-primary">
                          <ul class="list-unstyled px-2">
                            <li
                              class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                                Account # :
                              </span>
                              <span class="font-italic font-weight-bolder">{{
                                item.negotiate.account_number
                              }}</span>
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                                Type :
                              </span>
                              <span class="font-italic">{{
                                item.negotiate.account_type
                              }}</span>
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                                ><feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Type detail :</span
                              >
                              <span class="font-italic">{{
                                item.negotiate.account_type_detail || "-"
                              }}</span>
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                                Bureau code:
                              </span>
                              <span class="font-italic">{{
                                item.negotiate.bureau_code
                              }}</span>
                            </li>
                          </ul>
                        </b-col>
                        <b-col cols="4" class="px-2 border-right-primary">
                          <ul class="list-unstyled px-2">
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Account Status : </span
                              ><span class="font-italic">{{
                                item.negotiate.account_status
                              }}</span>
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Monthly payment : </span
                              ><span class="font-italic"
                                >$ {{ item.negotiate.monthly_payment | currency }}</span
                              >
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Date opened : </span
                              ><span class="font-italic">{{
                                item.negotiate.date_opened | myGlobal
                              }}</span>
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Comments :
                              </span>
                              <span class="font-italic">{{
                                item.negotiate.comments.length > 35
                                  ? item.negotiate.comments.substring(0, 35) + "..."
                                  : item.negotiate.comments || "-"
                              }}</span>
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Last Payment Status :
                              </span>
                              <span class="font-italic">{{
                                item.negotiate.last_payment | myGlobal
                              }}</span>
                            </li>
                          </ul>
                        </b-col>
                        <b-col cols="4" class="px-2">
                          <ul class="list-unstyled px-2">
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />No. of Months (terms) :
                              </span>
                              <span class="font-italic">{{
                                item.negotiate.n_months || "-"
                              }}</span>
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Balance :
                              </span>
                              <span class="font-italic">
                                $ {{ item.negotiate.balance | currency }}</span
                              >
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Credit Limit :
                              </span>
                              <span class="font-italic"
                                >${{ item.negotiate.credit_limit | currency }}</span
                              >
                            </li>
                            <li
                              class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                            >
                              <span
                                class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                              >
                                <feather-icon
                                  icon="CircleIcon"
                                  class="mr-50"
                                  size="5"
                                />Payment Status :
                              </span>
                              <span class="font-italic">{{
                                item.negotiate.payment_status || "-"
                              }}</span>
                            </li>
                          </ul>
                        </b-col>
                      </b-row>
                    </template>
                    <template #cell(classification_ncr)="{ item }">
                      <div class="d-flex justify-content-center align-items-center h-100">
                        <h5
                          class="text-uppercase font-weight-bolder text-center"
                          :style="`color: ${
                            textColor[item.negotiate.classification_ncr_id]
                          }`"
                        >
                          {{ item.negotiate.classification_ncr }}
                        </h5>
                      </div>
                    </template>

                    <template #cell(priority)="{ item }">
                      <div
                        class="w-100 d-flex justify-content-center align-items-center text-center"
                      >
                        <div
                          class="text-white d-flex justify-content-center align-items-center px-1 py-50 rounded-pill text-uppercase font-weight-bolder"
                           :class="{
                                'custom-success': item.negotiate.priority_origin === 'SETTLE',
                                'custom-warning': item.negotiate.priority_origin === 'NEEDS CONFIRMATION',
                                'custom-danger': item.negotiate.priority_origin === 'NOT SETTLE',
                              }"
                        >
                          {{ item.negotiate.priority_origin }}
                          <template
                            v-if="isDiferentPriority(item.negotiate)"
                          >
                            <span
                              class="change_classification rounded-lg"
                              :class="{
                                'custom-success': item.negotiate.priority === 'SETTLE',
                                'custom-warning': item.negotiate.priority === 'NEEDS CONFIRMATION',
                                'custom-danger': item.negotiate.priority === 'NOT SETTLE',
                              }"
                              v-b-tooltip.hover
                              title="Current Classification"
                              >{{ item.negotiate.priority == 'NEEDS CONFIRMATION' ? 'NEEDS...' : item.negotiate.priority }}
                              <feather-icon
                                icon="InfoIcon"
                                style="margin-left: 5px"
                              />
                            </span>
                          </template>
                        </div>
                      </div>
                    </template>

                    <template #cell(balance)="{ item, index }">
                      <div
                        class="w-100 d-flex justify-content-center align-items-center"
                        :id="`balance_${index}`"
                      >
                        <template v-if="!!duplicyBalance[item.negotiate.balance]">
                          <span
                            class="custom-squad rounded-sm mr-50"
                            :style="`background-color: ${
                              duplicyBalance[item.balance].color
                            } !important;`"
                          />
                          <b-tooltip :target="`balance_${index}`" placement="top">
                            {{ duplicyBalance[item.balance].cant }} coincidences
                          </b-tooltip>
                        </template>
                        $ {{ item.negotiate.balance | currency }}
                      </div>
                    </template>

                    <template #cell(high_credit)="{ item }">
                      $ {{ showHighCredit(item.negotiate) | currency }}
                    </template>

                    <template #cell(average)="{ item }">
                      <div class="d-flex align-items-center justify-content-center">
                        <span
                          :class="
                            item.negotiate.average == null ? 'font-weight-bolder text-warning' : null
                          "
                          >{{ showAverage(item.negotiate) | currencyZero }} %</span
                        >
                        <feather-icon
                          v-if="item.negotiate.average == null"
                          icon="AlertCircleIcon"
                          class="text-warning ml-50"
                          v-b-tooltip.hover.v-warning="'AVERAGE BY DEFAULT'"
                        />
                      </div>
                    </template>

                    <template #cell(detail)="{ toggleDetails, detailsShowing }">
                      <div
                        style="width: 40px !important"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <feather-icon
                          :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                          class="hover-me cursor-pointer"
                          size="22"
                          @click="toggleDetails"
                        />
                      </div>
                    </template>
                    <template #custom-foot>
                      <b-tr class="fixed-footer">
                        <b-th colspan="3"></b-th>
                        <b-th class="text-center custom-font-size text-white">Total</b-th>
                        <b-th
                          class="text-center font-weight-bolder text-success custom-font-size"
                        >
                          <div
                            style="width: 100px !important"
                            class="d-flex justify-content-end align-items-center"
                          >
                            <span
                              class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
                            >
                              $ {{ totalBalance | currency }}
                            </span>
                          </div>
                        </b-th>
                        <b-th
                          class="text-center font-weight-bolder text-success custom-font-size"
                        >
                          <div
                            style="width: 110px !important"
                            class="d-flex justify-content-center align-items-center"
                          >
                            <span
                              class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
                            >
                              $ {{ totalHighCredit | currency }}
                            </span>
                          </div>
                        </b-th>
                        <b-th
                          class="text-center font-weight-bolder text-success custom-font-size"
                        >
                          <div
                            style="width: 110px !important"
                            class="d-flex justify-content-center align-items-center"
                          >
                            <span
                              class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
                            >
                              {{ promAverage | currency }} %
                            </span>
                          </div>
                        </b-th>
                        <b-th />
                      </b-tr>
                    </template>
                  </b-table>
            </div>
          </b-col>
        </b-row>
      </b-container>

  </b-modal>
</template>

<script>
import DashboardServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/services/dashboard.services.js";
import { mapGetters } from "vuex";
import Fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/data/fields.js";
import ListLeads from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/ListLeads.vue";
import ShowFilters from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ShowFiltersAnalysisCR.vue";
export default {
  props: {
    item: Number,
    classification: Number,
    classification_ncr_id: Number,
    year: [Number, String],
    month: [Number, String],
    source: [Number],
    type: [Number],
    filters: {
      required: true,
      type: Object,
    },
    title: String,
  },
  components: {
    ListLeads,
    ShowFilters
  },
  data() {
    return {
      inputSearchLeads: {
        type: "input",
        inputType: "text",
        placeholder: "Search by name...",
        model: "",
      },
      isActive: false,
      Fields,
      items: [],
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      priorityColor: {
        high: "#00F380",
        medium: "#FFAF00",
        low: "#FE0000",
        SETTLE: "#00F380",
        "NEEDS CONFIRMATION": "#FFAF00",
        "NOT SETTLE": "#FE0000",
      },
      duplicyBalance: {},
      // ListLeads
      keyLeads: 0,
      paginateLeads: {
        current_page: 1,
        per_page: 10,
        total_rows: 0,
      },
      dataLeads: [],
      result_analysis_account_id: null,
      currentItemLead: {},
      // fin ListLeads
    };
  },
  computed: {
    visibleFields() {
      return this.Fields.filter((fields) => fields.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    totalBalance() {
      return this.items.reduce((sum, item) => {
        if (item.negotiate.action === "negotiate") {
          sum += parseFloat(item.negotiate.balance);
        }
        return sum;
      }, 0);
    },
    totalHighCredit() {
      return this.items.reduce((sum, item) => {
        if (item.negotiate.action.toLowerCase() === "negotiate") {
          sum += parseFloat(item.negotiate.high_credit);
        }
        return sum;
      }, 0);
    },
    promAverage() {
      const sum = this.items.reduce((sum, item) => {
        if (item.negotiate.action.toLowerCase() === "negotiate") {
          let average = item.negotiate.average;
          if (average == null) average = 60;
          sum += parseFloat(average);
        }
        return sum;
      }, 0);
      const total = this.items.length;
      return total > 0 ? sum / total : null;
    },
  },
  methods: {
    isDiferentPriority(item){
      return item.priority !== item.priority_origin && this.item != 1;
    },
    selectedLead(item) {
      this.result_analysis_account_id = item.result_analysis_account_id;
      this.currentItemLead = item;
    },
    async selectedPage(page) {
      this.paginateLeads.current_page = page;
      await this.getLeads();
    },
    async getLeads() {
      try {
        const params = {
          p_model: this.inputSearchLeads.model,
          p_per_page: this.paginateLeads.per_page,
          p_page: this.paginateLeads.current_page,
          p_type: this.type || null,
          p_source: this.source || null,
          p_month: this.month || null,
          p_year: this.year || null,
          p_item: this.item || null,
          p_classification: this.classification || null,
          p_classification_ncr_id: this.classification_ncr_id || null,
        };
        this.addPreloader();
        const { data } =
          await DashboardServices.getLeadsForSettlementDash(params);

        this.paginateLeads = {
          current_page: data.current_page,
          per_page: 10,
          total_rows: data.total,
        };
        this.dataLeads = data.data;
        if(this.dataLeads.length) {
          this.result_analysis_account_id = this.dataLeads[0].result_analysis_account_id;
          this.currentItemLead = this.dataLeads[0];
        }
        this.keyLeads += 1;
      } catch (error) {
        console.log("error in getLeads modal details: ", error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.totalRows = 0;
      this.perPage = 10;
      this.currentPage = 1;
      this.isActive = false;
      this.$emit("close");
    },
    async myProvider(ctx) {
      try {
        if(!this.keyLeads) await this.getLeads();
        if(this.dataLeads.length === 0) return [];
        const params = {
          p_type: this.type || null,
          p_source: this.source || null,
          p_month: this.month || null,
          p_year: this.year || null,
          p_item: this.item || null,
          p_classification: this.classification || null,
          p_classification_ncr_id: this.classification_ncr_id || null,
          p_raa_id: this.result_analysis_account_id || this.dataLeads[0].result_analysis_account_id,
        };
        this.addPreloader();
        const { data } = await DashboardServices.getReportModalDash(params);
        const myData = data;
        this.analyzeBalance(myData);
        this.items = myData;
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    getFilterByKey(key) {
      const filter = this.FiltersForSlot.filters.find(
        (filter) => filter.key === `key`
      );
      return filter;
    },
    analyzeBalance(array) {
      const colors = [
        "#EF5350",
        "#45B39D",
        "#F4D03F",
        "#F39C12",
        "#D35400",
        "#EC407A",
        "#AB47BC",
        "#9575CD",
        "#5C6BC0",
        "#42A5F5",
      ];

      const balances = array.map((item) => item.balance);
      const duplicyBalance = balances
        .filter((value, index, array) => {
          return index !== array.findIndex((el) => el === value);
        })
        .reduce((result, item, index) => {
          result[item] = {
            color: colors[index] ? colors[index] : null,
            cant: balances.filter((el) => el === item).length,
          };
          return result;
        }, {});

      this.duplicyBalance = { ...duplicyBalance };
    },
    showHighCredit(item) {
      item.high_credit =
        item.high_credit == null || item.high_credit == "-"
          ? 0
          : item.high_credit;
      return parseFloat(item.high_credit).toFixed(2);
    },
    showAverage(item) {
      if (item.average == null) {
        return 60;
      } else {
        return item.average;
      }
    },
    async refresh() {
      await this.myProvider();
    },
  },

  async created() {
    await this.getLeads()
    this.isActive = true;
  },
  watch: {
    async result_analysis_account_id(newValue) {
      if (newValue) {
        this.refresh();
      }
    },
  },
};

</script>
<style scoped>
.w-custom {
  width: 300px;
  max-width: 300px;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 9;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #0090e7 !important;
  color: white !important;
}
.custom-main-td-height{
  height: 80px;
  display: flex;
  align-items: center !important;
}
.custom-bg-gray{
  background-color: #FAFAFA !important;
}
.custom-bg-gray-dark{
  background-color: #2F2F31 !important;
}
.change_classification {
  width: 100px;
  height: 25px;
  text-align: center;
  position: absolute;
  bottom: 60%;
  left: 65%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-success{
  color: white !important;
  font-weight: bolder !important;
  background-color: #00F380 !important;
}
.custom-warning{
  color: white !important;
  background-color: #FFAF00 !important;
}
.custom-danger{
  color: white !important;
  font-weight: bolder !important;
  background-color: #FE0000 !important;
}
</style>

