<template>
  <b-row align-v="stretch" class="mt-3">
    <b-col sm="12" md="12" lg="12">
      <b-card class="px-1 rounded-lg">
        <div class="d-flex align-items-end mb-1" style="gap: 10px">
          <div>
            <h4 style="font-weight: bold" class="m-0">
              GENERAL SETTLEMENT JOURNEY
            </h4>
          </div>
          <div
            class="rounded"
            :class="isDarkSkin ? 'custom-bg-dark' : 'custom-bg'"
          >
            <img
              width="25"
              height="25"
              src="https://img.icons8.com/external-bluetone-bomsymbols-/91/external-general-general-office-bluetone-bluetone-bomsymbols--4.png"
              alt="external-general-general-office-bluetone-bluetone-bomsymbols--4"
              class="img-fluid"
            />
          </div>
        </div>

        <hr class="m-0 mb-2" style="border: 1px solid #0090e7; width: 100%" />
        <b-row>
          <b-col cols="12" sm="12" lg="3">
            <b-skeleton-table :columns="2" :rows="5" animated v-if="isLoading">
            </b-skeleton-table>
            <b-table-simple
              selectable
              v-else
              responsive
              :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
            >
              <b-thead class="bg-primary">
                <b-tr>
                  <b-th>STATE</b-th>
                  <b-th>QUANTITY</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(value, key) in generalItems" :key="key">
                  <b-td
                    ><div
                      class="d-flex align-items-center w-100 py-2 px-2 custom-td-item text-primary font-weight-bolder"
                    >
                      {{ value.category }}
                    </div></b-td
                  >
                  <b-td
                    class="text-center"
                    @click="
                      !!value.count ? openModal(key, value.category) : null
                    "
                  >
                    <div
                      class="d-flex justify-content-center align-items-center w-100 py-2 px-2 custom-td-item"
                      :class="!!value.count ? 'hoverable-row' : null"
                    >
                      {{ value.count || 0 }}
                    </div></b-td
                  >
                </b-tr>
              </b-tbody>
            </b-table-simple></b-col
          >
          <b-col cols="12" sm="12" lg="9">
            <b-skeleton-img v-if="isLoading" height="200px"> </b-skeleton-img>
            <e-charts
              v-else
              ref="line"
              auto-resize
              autoresize
              :options="option"
              @click="handleChartClick"
              class="echarts rounded-lg"
              :class="!isDarkSkin ? 'custom-bg-gray' : 'custom-bg-gray-dark'"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import ECharts from "vue-echarts";
import DetailSettlement from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/DetailSettlement.vue";
export default {
  components: {
    ECharts,
    DetailSettlement,
  },
  props: {
    year: [Number, String],
    month: [Number, String],
    source: [Number],
    type: [Number],
    generalItems: {
      required: true,
      type: Array,
      default: () => [],
    },
    isLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    option: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    setInitialTextColor() {
      this.option.series[0].label.color =
        this.skin === "dark" ? "#fff" : "#000";
      this.option.xAxis[0].axisLabel.color =
        this.skin === "dark" ? "#fff" : "#000";
      this.option.yAxis[0].axisLabel.color =
        this.skin === "dark" ? "#fff" : "#000";
    },
    openModal(index, title) {
      const params={
        item: index + 1,
        classification: null,
        title: title,
        classification_ncr_id: null,
      }
      this.$emit("openModal", params);
    },
    handleChartClick(params) {
      if (params.componentType === "series") {
        if (params.seriesType === "bar") {
          const dataIndex = params.dataIndex;
          const category = this.generalItems[dataIndex].category;
          this.openModal(dataIndex, category);
        }
      }
    },
  },
  computed: {
    skin() {
      return this.$store.getters["appConfig/skin"];
    },
  },
  watch: {
    isDarkSkin(value) {
      this.option.series[0].label.color = value ? "#fff" : "#000";
      this.option.xAxis[0].axisLabel.color =
        this.skin === "dark" ? "#fff" : "#000";
      this.option.yAxis[0].axisLabel.color =
        this.skin === "dark" ? "#fff" : "#000";
    },
  },
  async created() {
    this.setInitialTextColor();
  },
};
</script>

<style scoped lang="scss"  >
@import "../commons/style/shared-styles.scss";
.echarts {
  width: 100% !important;
  height: 100% !important;
}
</style>
