<template>
  <b-card class="px-1 h-100 rounded-lg">
    <div
      class="border-bottom-primary pb-50 d-flex flex-wrap justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <h4 class="font-weight-bolder text-uppercase m-0 mr-1">
          FOR ACCOUNT TYPE
        </h4>
        <img
          width="25"
          height="25"
          src="https://img.icons8.com/external-bluetone-bomsymbols-/91/external-atom-digital-design-bluetone-set-2-bluetone-bomsymbols-.png"
          alt="external-atom-digital-design-bluetone-set-2-bluetone-bomsymbols-"
        />
      </div>

      <div class="d-flex w-auto">
        <b-button
          @click="typeView = 'list'"
          :variant="typeView == 'list' ? 'primary' : 'outline-primary'"
          class="mr-50 p-50"
        >
          <feather-icon icon="ListIcon" size="18" />
        </b-button>
        <b-button
          @click="typeView = 'graphic'"
          :variant="typeView == 'graphic' ? 'primary' : 'outline-primary'"
          class="p-50"
        >
          <feather-icon icon="BarChart2Icon" size="18" />
        </b-button>
      </div>
    </div>

    <hr class="m-0 mb-2" style="border: 1px solid #0090e7; width: 100%" />
    <b-skeleton-table
      :count="skeletonCount"
      :width="skeletonWidth"
      :height="skeletonHeight"
      animated
      v-if="isLoading"
    >
    </b-skeleton-table>
    <b-table-simple
      v-show="!isLoading"
      responsive
      :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
      v-if="typeView == 'list'"
    >
      <b-thead>
        <b-tr>
          <b-th>STATE</b-th>
          <b-th>BY SOFT</b-th>
          <b-th>BY SPECIALISTS</b-th>
          <b-th>BY SELLER</b-th>
          <b-th>SOLD</b-th>
          <b-th>OFFERED</b-th>
          <b-th>SETTLEDS</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(value, key) in accountTypeItems" :key="key">
          <b-td>
            <div
              class="d-flex align-items-center w-100 py-2 px-2 custom-td-item text-primary font-weight-bolder"
            >
              {{ value.name }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.by_soft != '0'
                ? openModal(value.name, `${value.name} - BY SOFT`, 1)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.by_soft != 0 ? 'hoverable-row' : null"
            >
              {{ value.by_soft }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.by_specialists != '0'
                ? openModal(value.name, `${value.name} - BY SPECIALISTS`, 2)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.by_specialists != 0 ? 'hoverable-row' : null"
            >
              {{ value.by_specialists }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.by_seller != '0'
                ? openModal(value.name, `${value.name} - BY SELLER`, 3)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.by_seller != 0 ? 'hoverable-row' : null"
            >
              {{ value.by_seller }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.sold != '0'
                ? openModal(value.name, `${value.name} - SOLD`, 4)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.sold != 0 ? 'hoverable-row' : null"
            >
              {{ value.sold }}
            </div></b-td
          >
          <b-td
            class="text-center"
            @click="
              value.offered != '0'
                ? openModal(value.name, `${value.name} - OFFERED`, 5)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.offered != 0 ? 'hoverable-row' : null"
            >
              {{ value.offered }}
            </div>
          </b-td>
          <b-td
            class="text-center"
            @click="
              value.settleds != 0
                ? openModal(value.name, `${value.name} - SETTLEDS`, 6)
                : null
            "
            ><div
              class="custom-td-div custom-td-item"
              :class="value.settleds != 0 ? 'hoverable-row' : null"
            >
              {{ value.settleds }}
            </div></b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div v-else class="d-block">
      <graphics-components
        :xAxisData="xAxisData"
        :yAxisData="yAxisData"
        @selectItemChart="selectItemChart"
      />
    </div>
  </b-card>
</template>
<script>
import DetailSettlement from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/DetailSettlement.vue";
import GraphicsComponents from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/components/Graphics.vue";
export default {
  components: {
    DetailSettlement,
    GraphicsComponents,
  },
  props: {
    year: [Number, String],
    month: [Number, String],
    source: [Number],
    type: [Number],
    accountTypeItems: {
      required: true,
      type: Array,
      default: () => [],
    },
    isLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    viewDefault: {
      type: String,
      default: "list",
      required: false,
    },
  },
  mounted() {
    if (!!this.viewDefault) {
      this.typeView = this.viewDefault;
    }
  },
  data() {
    return {
      skeletonCount: 5,
      skeletonWidth: "100%",
      skeletonHeight: "2rem",
      typeView: "list",
      xAxisData: [],
      yAxisData: {},
    };
  },
  methods: {
    openModal(classification_ncr_id, title, index) {
      let classifications = {
        "LATE PAYMENTS": 1,
        "CHARGE OFF": 2,
        COLLECTION: 3,
        REPOSSESSION: 4,
        "MEDICAL BILLS": 9,
        "OPEN ACCOUNTS": 10,
        TOTAL: null,
      };
      const params={
        item: index,
        classification: null,
        title: title,
        classification_ncr_id: classifications[classification_ncr_id],
      }
      this.$emit("openModal", params);
    },
    generateChart() {
      const seriesA = this.accountTypeItems.map((item) => item.by_soft);
      const seriesB = this.accountTypeItems.map((item) => item.by_specialists);
      const seriesC = this.accountTypeItems.map((item) => item.by_seller);
      const seriesD = this.accountTypeItems.map((item) => item.sold);
      const seriesE = this.accountTypeItems.map((item) => item.offered);
      const seriesF = this.accountTypeItems.map((item) => item.settleds);

      const sumA = seriesA.reduce((sum, item) => {
        return sum += parseInt(item);
      },0);
      const sumB = seriesB.reduce((sum, item) => {
        return sum += parseInt(item);
      },0);
      const sumC = seriesC.reduce((sum, item) => {
        return sum += parseInt(item);
      },0);
      const sumD = seriesD.reduce((sum, item) => {
        return sum += parseInt(item);
      },0);
      const sumE = seriesE.reduce((sum, item) => {
        return sum += parseInt(item);
      },0);
      const sumF = seriesF.reduce((sum, item) => {
        return sum += parseInt(item);
      },0);

      // const xAxisData = this.accountTypeItems.map((item) => item.name);
      const xAxisData = [
        {name:'by soft' , total: sumA },
        {name:'by specialists', total: sumB },
        {name:'by seller' , total: sumC },
        {name:'sold' , total: sumD },
        {name:'offered' , total: sumE },
        {name:'settleds' , total: sumF }];
      this.xAxisData = xAxisData;

      const yAxisData = this.accountTypeItems.map((item) => item.name);
      let data = [];
      yAxisData.forEach((item, index) =>{
        data[item] = [seriesA[index], seriesB[index], seriesC[index], seriesD[index], seriesE[index], seriesF[index]];
      })
      this.yAxisData = {...data};
    },
    selectItemChart({ xAxisIndex, yAxisIndex }) {

      const yAxisItem = this.accountTypeItems[yAxisIndex];
      const xAxisItem = this.xAxisData[xAxisIndex];
      const clasiffication_ncr = yAxisItem.name;
      const { name } = xAxisItem;
      const itemName = `${name} - ${yAxisItem.name}`;
      this.openModal(clasiffication_ncr, itemName, xAxisIndex + 1);
    },
  },
  watch: {
    typeView(newValue) {
      if (newValue) {
        this.generateChart();
      }
      this.$emit("selectView", newValue);
    },
  },
};
</script>
<style scoped lang="scss" >
@import "../commons/style/shared-styles.scss";
  th {
    vertical-align: middle !important;
  }
</style>
