<template>
  <div>
    <MainFilter
      class="mb-2"
      @selectedFilters="getSelectedFilters"
      @updateService="update"
      :isLoading="isLoading"
    />
    <GeneralBar
      v-if="selected"
      :month="selected.date.month"
      :source="selected.sourceOption"
      :year="selected.date.year"
      :type="convertType(selected.type)"
      :generalItems="generalItems"
      :isLoading="isLoading"
      :option="option"
      @openModal="openModal"
      ref="generalBar"
    />
    <b-row align-v="stretch" class="mt-2">
      <b-col sm="12" lg="6">
        <ForClassificationCard
          v-if="selected"
          :month="selected.date.month"
          :source="selected.sourceOption"
          :year="selected.date.year"
          :type="convertType(selected.type)"
          :classificationItems="classificationItems"
          :isLoading="isLoading"
          ref="forClassificationCard"
          :key="keyTable"
          :viewDefault="viewClassification"
          @selectView="selectViewClassification"
          @openModal="openModal"
        />
      </b-col>
      <b-col sm="12" lg="6">
        <ForAccountTypeCard
          v-if="selected"
          :month="selected.date.month"
          :source="selected.sourceOption"
          :year="selected.date.year"
          :type="convertType(selected.type)"
          :accountTypeItems="accountTypeItems"
          :isLoading="isLoading"
          ref="forAccountTypeCard"
          :key="keyTable"
          :viewDefault="viewType"
          @selectView="selectViewType"
          @openModal="openModal"
        />
      </b-col>
    </b-row>
    <DetailSettlement
      v-if="modalShow"
      @close="closeModal"
      :year="selected.date.year"
      :month="selected.date.month"
      :source="selected.sourceOption"
      :type="convertType(selected.type)"
      :item="item"
      :filters="selected"
      :classification="classification"
      :classification_ncr_id="classification_ncr_id"
      :title="title"
    />
  </div>
</template>

<script>
import GeneralBar from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/GeneralBar.vue";
import ForClassificationCard from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/ForClassificationCard.vue";
import ForAccountTypeCard from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/ForAccountTypeCard.vue";
import MainFilter from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/commons/MainFilter.vue";
import DashboardServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/services/dashboard.services.js";
import echarts from "echarts/lib/echarts";
import DetailSettlement from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/DetailSettlement.vue";
export default {
  components: {
    GeneralBar,
    ForClassificationCard,
    ForAccountTypeCard,
    MainFilter,
    DetailSettlement
  },
  data() {
    return {
      modalShow: false,
      item: null,
      classification: null,
      classification_ncr_id: null,
      title: null,
      selected: {
        type: null,
        sourceOption: null,
        date: {
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        },
      },
      classificationItems: [],
      accountTypeItems: [],
      generalItems: [],
      isLoading: false,
      option: {
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],
            axisLabel: {
              color: "#fff",
              margin: 14,
              fontSize: 13,
              interval: 0,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              margin: 20,
              fontSize: 14,
              color: "#fff",
            },
            axisLine: { show: true },
          },
        ],
        series: [
          {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#83bff6" }, // Color inicial
              { offset: 0.5, color: "#188df0" }, // Color medio
              { offset: 1, color: "#188df0" }, // Color final
            ]),
            type: "bar",
            barGap: 0,
            label: {
              show: true,
              position: "top",
              distance: 12,
              align: "center",
              verticalAlign: "middle",
              rotate: 1,
              formatter: "{c}",
              fontSize: 12,
              color: "#fff",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [7, 7, 0, 0],
              },
            },
            data: [],
            showBackground: true,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.4)",
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#2378f7" },
                  { offset: 0.7, color: "#2378f7" },
                  { offset: 1, color: "#83bff6" },
                ]),
              },
            },
          },
        ],
      },
      keyTable: 0,
      viewClassification: null,
      viewType: null,
    };
  },

  methods: {
    openModal(params){
      this.modalShow = true;
      this.item = params.item;
      this.classification = params.classification;
      this.title = params.title;
      this.classification_ncr_id = params.classification_ncr_id;
    },
    closeModal(){
      this.modalShow = false;
    },
    async getSettlementDashboard() {
      try {
        this.isLoading = true;
        const params = {
          p_type: this.convertType(this.selected.type) || null,
          p_source: this.selected.sourceOption || null,
          p_month: this.selected.date.month || null,
          p_year: this.selected.date.year || null,
        };
        const { data } = await DashboardServices.getSettlementDashboard(params);
        this.generalItems = data.general;
        // this.option.xAxis[0].data = data.general.map((x) => x.category);
        this.option.xAxis[0].data = [
          'BY SOFT',
          'BY SPECIALISTS',
          'BY SELLER',
          'SOLD',
          'OFFERED',
          'SETTLEDS'
        ];
        this.option.series[0].data = data.general.map((x) => x.count);
        this.classificationItems = data.by_classification;
        this.accountTypeItems = data.by_account_type;
        this.keyTable += 1;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getSelectedFilters(data) {
      if (data) {
        this.selected = data;
      }
      await this.getSettlementDashboard();
    },
    convertType(type) {
      const data = {
        AUTOMATIC: 1,
        MANUAL: 2,
      };
      return data[type];
    },
    async update() {
      try {
        this.addPreloader();
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    selectViewClassification(view){
      this.viewClassification = view;
    },
    selectViewType(view){
      this.viewType = view;
    },
  },
};
</script>

